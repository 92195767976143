<template>
  <v-app :style="$route.name == 'encuesta' || $route.name == 'encuesta-temp' ?'background-color: #0095EB':'' ">
    <!-- <v-app style="background-color: #0095EB"> -->
    <SideBar v-if="$store.state.token_encuestas_asul != null && $route.name !== 'Login' && $route.name !== 'encuesta' && $route.name != 'encuesta-temp' "  />

    <v-main v-show="!$store.state.stateLoad">
      <router-view   />
    </v-main>
    <div v-if="$store.state.stateLoad" id="efecto_carga">
      <div class="loader3"></div>
    </div>
  </v-app>
</template>

<script>

import SideBar from "@/modules/general/components/SideBar";

export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    // console.log(this.$route.name);
    // if (!this.$store.state.token_encuestas_asul && this.$route.name != 'Login') {
      
    //     this.$router.push({
    //       name: "encuesta",
    //     });
      
    // }
  },
  components: {
    SideBar,
  },
};
</script>
<style>
.campo-obligatorio {
  color: red;
  margin-left: 2px;
}
.swal2-container {
  font-family: Arial, Helvetica, sans-serif;
}
#efecto_carga {
  background-color: rgb(250 240 245 / 73%);
  height: 100%;
  width: 100%;
  position: fixed;
  transition: all 1s ease;
  z-index: 1000;
}




.loader3 {
  color: #19b578db;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



</style>
