import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    loading: false,
    token_encuestas_asul: null,
    user_encuestas_asul: {},
    stateLoad:false
  },
  mutations: {
    setToken(state, token) {
      console.log(token);
      state.token_encuestas_asul = token;
    },
    setUser(state, user) {
      console.log(user);
      state.user_encuestas_asul = user;
    },
  
    cerrarSesion(state){
      state.token_encuestas_asul = null;
      state.user_encuestas_asul = {};
      location.reload();
    }
  },
  actions: {},
  modules: {},
});
