import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/HomeView.vue"),
  },
  {
    path: "/validar-codigo-formulario",
    name: "validar-codigo-formulario",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/modules/encuesta/ValidarCodigoFormulario.vue"),
  },
  {
    path: "/consultar-respuestas-formularios",
    name: "consultar-respuestas-formularios",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/modules/encuesta/ConsultarFormulariosRespuestas.vue"),
  },
  {
    path: "/consultar-formularios",
    name: "consultar-formularios",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/modules/encuesta/ConsultarFormularios.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/modules/login/Login.vue"),
  },
  {
    path: "/formulario/:id_encuesta",
    name: "encuesta",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/modules/encuesta/Encuesta.vue"),
  },
  {
    path: "/formulario-temp/:id_encuesta",
    name: "encuesta-temp",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/modules/encuesta/EncuestaTemp.vue"),
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '@/modules/login/Login.vue')
  // },
  {
    path: "/**",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/modules/general/NotFoundView.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.state.token_encuestas_asul != null) {
      next();
    } else {
      next("/formulario/1");
    }
  } else {
    next();
  }
});

export default router;
